<script async setup lang="ts">
const app = useNuxtApp()
const route = useRoute()

const { fetchData, data, isLoading } = usePloneDataset()

await fetchData()

type layoutOptions = {
  [key: string]: string
}

const layouts: layoutOptions = {
  'News Item': 'layout-news-item',
  'Event': 'layout-event',
  'File': 'layout-file',
  'Image': 'layout-image',
  'cka.event': 'layout-event',
  'cka.section': 'layout-listing',
  'cka.project': 'layout-project',
  'cka.profile': 'layout-profile',
  'cka.competition': 'layout-competition',
  'cka.working_group': 'layout-work-group',
  'cka.calcInt': 'layout-calculator-interiors',
  'cka.calcRPUS': 'layout-calculator-regulatory-plan',
  'cka.calcPKS': 'layout-calculator-land-constructions-next',
  'cka.calcUZ': 'layout-calculator-land-use',
  'cka.calcCOS': 'layout-calculator-competitions',
  'cka.city': 'layout-city',
  'cka.school': 'layout-school',
  'cka.official_desk': 'layout-official-desk',
  'cka.official_desk_record': 'layout-official-desk-record',
  'cka.faq': 'layout-faq-item',
  'cka.labor_exchange': 'layout-labor-exchange',
  'cka.labor_ad': 'layout-labor-ad',
}

const layout = computed(() => {
  if (data.value.layout === 'error') {
    throw createError({ statusCode: Number(data.value?.error_code), fatal: true })
  }

  if (!data.value.layout) {
    return 'layout-display'
  }

  if (data.value.layout === 'cover') {
    return 'layout-cover'
  }

  if (data.value.layout === 'form') {
    return 'layout-form'
  }

  if (data.value['@id'].endsWith('pozemni-a-krajinarske-stavby-do-30-6-2024')) {
    return 'layout-calculator-land-constructions'
  }

  if (layouts[data.value['@type']]) {
    return layouts[data.value['@type']]
  }

  if (data.value.layout === 'display') {
    return 'layout-display'
  }

  if (data.value.layout === 'news_listing') {
    return 'layout-news'
  }

  if (data.value.layout === 'view-events') {
    return 'layout-events'
  }

  if (data.value.layout === 'profiles_listing') {
    return 'layout-profiles'
  }

  if (data.value.layout === 'jurors_listing' || data.value.layout === 'probono_listing') {
    return 'layout-jurors'
  }

  if (data.value.layout === 'faq_listing') {
    return 'layout-faq-listing'
  }

  if (['projects_listing', 'deset_listing'].includes(data.value.layout)) {
    return 'layout-projects'
  }

  if (['events_listing', 'events_archive', 'cpv_listing', 'cpv_archive'].includes(data.value.layout)) {
    return 'layout-events'
  }

  if (data.value.layout === 'city_listing') {
    return 'layout-city-architects'
  }

  if (data.value.layout === 'press_release_listing') {
    return 'layout-press'
  }

  if (data.value.layout === 'statement_listing') {
    return 'layout-press'
  }

  if (data.value.layout.startsWith('competitions_')) {
    return 'layout-competitions'
  }

  if (data.value.layout === 'view-profiles') {
    return 'layout-profiles'
  }

  if (data.value.layout === 'eso9') {
    return 'layout-eso9'
  }

  return 'layout-display'
})
</script>

<template>
  <article class="page">
    <component v-if="data && layout" :class="[`layout--${layout}`]" :is="layout" :data="data" :loading="isLoading"
      @reload="fetchData" />
  </article>
</template>

<style>
.page-enter-active {
  transition: all 0.1s ease-out;
}

.page-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(3px);
  filter: blur(1px);
}
</style>
